import React from "react"
import styled, { css } from "styled-components"
import Image from "gatsby-image"
import FrancoisLogo from "../images/francoisLogoNonBorder.svg"
import CompanyInfoLinks from "./CompanyInfoLinks"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0;
  border-bottom: 1px solid #dadada;
  .logoArea {
    width: 40%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .gatsby-image-wrapper {
      width: 30%;
    }
    .svgLogo {
      width: 20%;
    }
  }
  .contentsArea {
    width: 60%;
    padding: 30px;
    h3 {
      font-weight: bold;
      font-size: 1.32em;
      margin-bottom: 1.32em;
    }
    dl {
      display: flex;
      flex-flow: row nowrap;
      padding: 0.62em 0;
      dt {
        width: 100px;
      }
    }
  }
  ${() =>
    media.tablet(css`
      .logoArea {
        width: 20%;
        .gatsby-image-wrapper {
          width: 80%;
        }
        .svgLogo {
          width: 60%;
        }
      }
      .contentsArea {
        width: 80%;
        dl {
          flex-flow: row wrap;
          dt {
            width: 100%;
            font-size: 0.9em;
            margin-bottom: 5px;
          }
          dd {
            font-weight: normal;
            color: gray;
          }
        }
      }
    `)}
  ${() =>
    media.sp(css`
      .logoArea {
        width: 100%;
        .gatsby-image-wrapper {
          width: 30%;
        }
        .svgLogo {
          width: 25%;
        }
      }
      .contentsArea {
        width: 100%;
      }
    `)}
`

const CompanyInformations = props => {
  return (
    <StyledDiv>
      <div className="logoArea">
        {props.logofluid && <Image fluid={props.logofluid} />}
        {props.svglogo && (
          <img
            className="svgLogo"
            src={FrancoisLogo}
            alt="フランソアの会社ロゴ"
          />
        )}
      </div>
      <div className="contentsArea">
        <h3>{props.company}</h3>
        {props.eDate && (
          <dl>
            <dt>設立</dt>
            <dd>{props.eDate}</dd>
          </dl>
        )}
        {props.equity && (
          <dl>
            <dt>資本金</dt>
            <dd>{props.equity}</dd>
          </dl>
        )}
        {props.revenue && (
          <dl>
            <dt>売上高</dt>
            <dd dangerouslySetInnerHTML={{ __html: props.revenue }} />
          </dl>
        )}
        {props.staffs && (
          <dl>
            <dt>従業員数</dt>
            <dd dangerouslySetInnerHTML={{ __html: props.staffs }} />
          </dl>
        )}
        {props.business && (
          <dl>
            <dt>事業内容</dt>
            <dd>{props.business}</dd>
          </dl>
        )}
        {props.hq && (
          <dl>
            <dt>本社所在地</dt>
            <dd>{props.hq}</dd>
          </dl>
        )}
        {props.tel && (
          <dl>
            <dt>電話番号</dt>
            <dd>{props.tel}</dd>
          </dl>
        )}
      </div>
      <CompanyInfoLinks
        linkWeb={props.outLink}
        linkLoc={props.inLinkLoc}
        linkDep={props.inLinkDep}
        linkPdf={props.linkToPdf}
      />
      {props.children}
    </StyledDiv>
  )
}

export default CompanyInformations
