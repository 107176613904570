import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { HiOutlineExternalLink, HiArrowNarrowRight } from "react-icons/hi"
import { IconContext } from "react-icons"
// import { Document, Page } from "react-pdf"
import { media } from "../styles/media"
import { FaBookOpen } from "react-icons/fa"

const InnerLink = () => (
  <IconContext.Provider
    value={{
      color: "#008742",
      size: "1.32em",
    }}
  >
    <HiArrowNarrowRight />
  </IconContext.Provider>
)

const OuterLink = () => (
  <IconContext.Provider
    value={{
      color: "#E50012",
      size: "1.32em",
    }}
  >
    <HiOutlineExternalLink />
  </IconContext.Provider>
)

const IconBook = () => (
  <IconContext.Provider value={{ color: "black", size: "1.62em" }}>
    <FaBookOpen />
  </IconContext.Provider>
)

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10%;
  a {
    margin-left: 40px;
    line-height: 2em;
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.colors.primary.green};
    color: ${({ theme }) => theme.colors.primary.green};
    padding-left: 30px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    transition: 0.4s;
    svg {
      margin: 0 15px;
    }
    &.CSSredButton {
      color: ${({ theme }) => theme.colors.primary.red};
      border: 2px solid ${({ theme }) => theme.colors.primary.red};
    }
    &:hover {
      background: ${({ theme }) => theme.colors.primary.green};
      color: white;
      transform: translateX(3px);
      &.CSSredButton {
        background: ${({ theme }) => theme.colors.primary.red};
      }
    }
  }
  .pdfLink {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-top: 1.32em;
    a {
      display: inline-flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      padding: 0.32em 0em;
      border-radius: 0;
      background: white;
      color: black;
      border: none;
      svg {
        margin: 0;
        margin-right: 1.62em;
      }
      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        border-radius: 3px;
        background: #3a3a3a;
        transition: 0.3s;
        opacity: 0;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  ${() =>
    media.tablet(css`
      padding: 0;
      a {
        font-size: 0.9em;
        margin-left: 20px;
      }
      .pdfLink {
        a:after {
          opacity: 1;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      flex-flow: column nowrap;
      a {
        margin-bottom: 10px;
      }
    `)}
`

const CompanyInfoLinks = props => {
  return (
    <StyledDiv>
      {props.linkWeb && (
        <a
          rel="noopener noreferrer"
          href={props.linkWeb}
          target="_blank"
          className="CSSredButton"
        >
          ウェブサイト
          <OuterLink />
        </a>
      )}
      {props.linkDep && (
        <Link to={props.linkDep}>
          部門一覧
          <InnerLink />
        </Link>
      )}
      {props.linkLoc && (
        <Link to={props.linkLoc}>
          拠点一覧
          <InnerLink />
        </Link>
      )}
      <div className="pdfLink">
        {props.linkPdf && (
          <a href={props.linkPdf}>
            <IconBook />
            会社パンフレット
          </a>
        )}
      </div>
    </StyledDiv>
  )
}

export default CompanyInfoLinks
