import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import CompanyInformations from "../components/CompanyInformations"
import Layout from "../components/Layout"
import PageHeaderMedia from "../components/PageHeaderMedia"
import SEO from "../components/SEO"

const StyledDiv = styled.div`
  padding: 0px 0 80px;
`

const CompaniesProfile = () => {
  const data = useStaticQuery(query)
  const { strapiCompanyLists: porche } = data
  return (
    <Layout>
      <SEO>
        <title>会社概要｜フランソア</title>
        <meta name="description" content=" 会社概要 創業 19..." />
      </SEO>
      <PageHeaderMedia
        fluid={porche.headerMedia.childImageSharp.fluid}
        pageTitles={porche.pageTitle}
      />
      <StyledDiv>
        <span id="francois"></span>
        <CompanyInformations
          logofluid=""
          svglogo="francois"
          eDate={porche.companyLIst[0].establishedDate}
          company={porche.companyLIst[0].companyName}
          equity={porche.companyLIst[0].capitalAmount}
          revenue={porche.companyLIst[0].salesRev}
          staffs={porche.companyLIst[0].memberNum}
          buisiness={porche.companyLIst[0].ourBusiness}
          hq={porche.companyLIst[0].locationAddress}
          tel={porche.companyLIst[0].phoneNum}
          outLink={porche.companyLIst[0].linkToWeb}
          inLinkDep={porche.companyLIst[0].linkToDep}
          inLinkLoc={porche.companyLIst[0].linkToLocation}
          linkToPdf="/pdf/leaflet/"
        ></CompanyInformations>
        <span id="nbk"></span>
        <CompanyInformations
          logofluid={porche.companyLIst[1].companyLogo.childImageSharp.fluid}
          svglogo=""
          eDate={porche.companyLIst[1].establishedDate}
          company={porche.companyLIst[1].companyName}
          equity={porche.companyLIst[1].capitalAmount}
          revenue={porche.companyLIst[1].salesRev}
          staffs={porche.companyLIst[1].memberNum}
          buisiness={porche.companyLIst[1].ourBusiness}
          hq={porche.companyLIst[1].locationAddress}
          tel={porche.companyLIst[1].phoneNum}
          outLink={porche.companyLIst[1].linkToWeb}
          inLinkDep={porche.companyLIst[1].linkToDep}
          inLinkLoc={porche.companyLIst[1].linkToLocation}
        />
        <span id="sweets"></span>
        <CompanyInformations
          logofluid={porche.companyLIst[2].companyLogo.childImageSharp.fluid}
          svglogo=""
          eDate={porche.companyLIst[2].establishedDate}
          company={porche.companyLIst[2].companyName}
          equity={porche.companyLIst[2].capitalAmount}
          revenue={porche.companyLIst[2].salesRev}
          staffs={porche.companyLIst[2].memberNum}
          buisiness={porche.companyLIst[2].ourBusiness}
          hq={porche.companyLIst[2].locationAddress}
          tel={porche.companyLIst[2].phoneNum}
          outLink={porche.companyLIst[2].linkToWeb}
          inLinkDep={porche.companyLIst[2].linkToDep}
          inLinkLoc={porche.companyLIst[2].linkToLocation}
        />
      </StyledDiv>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiCompanyLists {
      pageTitle
      headerMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      companyLIst {
        id
        capitalAmount
        companyName
        establishedDate
        faxNum
        locationAddress
        memberNum
        ourBusiness
        phoneNum
        salesRev
        companyLogo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        linkToDep
        linkToLocation
        linkToWeb
      }
    }
  }
`

export default CompaniesProfile
